import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function WelcomeDogIllustration({ ...other }) {
  const theme = useTheme();

  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg width="276" height="202.5" viewBox="0 0 184 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M133 130H183V67L147.932 102.177L133 116.593V130Z" fill="#495057" stroke="#495057" strokeWidth="0.605812" strokeMiterlimit="10"/>
      <path d="M79.8766 21.1718C79.876 21.1187 79.868 21.0707 79.8604 21.0224C79.9181 20.5343 79.6415 19.9975 79.0268 20C76.6516 20.0096 74.0116 20.1149 72.4394 22.0307C71.7573 22.5646 71.4223 23.4853 70.995 24.4246C69.6756 27.325 70.0774 31.0149 70.0028 34.1332C69.9832 34.9545 70.851 35.1906 71.3697 34.8482C73.2291 34.906 75.0886 34.9147 76.9482 34.8562C77.4487 34.8405 77.9398 34.8618 78.357 34.7825C78.4621 34.781 78.5591 34.7598 78.6484 34.725C78.7226 34.7454 79.3536 34.7596 79.4416 34.7596C79.9941 34.7596 79.8976 34.5997 79.9063 34.1567C79.9673 34.0399 79.971 33.3276 79.9741 33.1655C80.0542 29.0682 79.9276 25.2696 79.8766 21.1718V21.1718Z" fill="#495057"/>
      <path d="M81 69H54V73H81V69Z" fill="#495057"/>
      <path d="M133.5 31C140.956 31 147 24.9558 147 17.5C147 10.0442 140.956 4 133.5 4C126.044 4 120 10.0442 120 17.5C120 24.9558 126.044 31 133.5 31Z" fill="#345BFF"/>
      <path d="M29 127C34.5228 127 39 122.523 39 117C39 111.477 34.5228 107 29 107C23.4772 107 19 111.477 19 117C19 122.523 23.4772 127 29 127Z" fill="#EBEFFF"/>
      <path d="M81 50.9896C77.2944 52.9115 73.068 54 68.5816 54C56.3874 54 46.1134 45.9579 43 35H80.8781L81 50.9896Z" fill="#EBEFFF"/>
      <path d="M124.792 64.5492C124.772 63.0205 124.752 61.4917 124.733 59.963C124.732 59.9231 124.722 59.89 124.714 59.8556C124.714 59.8477 124.717 59.8409 124.717 59.8327C124.773 57.4397 124.83 55.0466 124.887 52.6535C124.89 52.5263 124.849 52.4286 124.785 52.3569L124.798 52.0951C124.809 51.9728 124.817 51.8503 124.828 51.728C124.832 51.6858 124.829 51.6475 124.824 51.6114L124.923 49.7308C124.927 49.6502 124.913 49.5819 124.887 49.5236C124.933 49.2677 124.795 48.9814 124.471 49.0009C121.334 49.19 118.373 51.039 116.784 53.8247C115.86 55.445 115.495 57.3075 115.342 59.1584C115.173 61.2144 115.196 63.2918 115.153 65.3536C115.102 67.8168 115.051 70.2799 115 72.7431C114.993 73.0882 115.299 73.2273 115.553 73.1636C115.591 73.1927 115.634 73.2184 115.686 73.2361C116.63 73.5583 117.563 73.9159 118.476 74.3207C118.56 74.3578 118.645 74.3952 118.729 74.4326L121.547 76.5261C121.742 76.6712 121.949 76.7516 122.157 76.7793C122.4 77.0796 122.626 77.3949 122.841 77.7127C123.275 78.3546 124.084 79.2673 123.777 80.1006C123.665 80.4038 123.879 80.6106 124.125 80.6425C124.209 81.1432 125.007 81.119 125 80.5634C124.931 75.2253 124.861 69.8872 124.792 64.5492V64.5492Z" fill="#EBEFFF"/>
      <path d="M114.847 113.331C114.834 112.803 115.068 112.348 114.675 112.223C114.555 112.01 113.793 111.967 113.483 111.887C110.353 111.084 107.906 108.457 107.526 105.219C107.435 104.439 106.685 104.104 106.092 104.218C105.916 104.083 105.699 104 105.454 104H104.503C104.198 104 103.297 104.047 103.092 104.272C102.654 104.376 102.858 104.804 102.789 105.357C102.485 107.767 103.177 110.184 104.435 112.278C105.186 113.529 106.651 114.309 106.796 115.438C106.909 116.323 106.919 117.169 106.796 118.393C105.802 118.608 105.034 118.972 104.161 119.448C104.103 119.465 103.857 119.628 103.805 119.651C102.913 120.038 102.178 120.638 101.496 121.311C100.019 122.77 99.3874 124.635 99.3144 126.73C99.311 126.828 99.3211 126.917 99.3382 127.001C99.2736 127.303 99.2207 127.611 99.181 127.925C99.1634 128.064 99.1735 128.187 99.2031 128.297C99.185 128.329 99.1709 128.361 99.1521 128.394C98.5518 129.435 99.8672 130.384 100.681 129.843C100.771 129.864 100.867 129.879 100.973 129.877C103.101 129.836 105.229 129.796 107.357 129.756C108.073 129.742 108.417 129.163 108.395 128.609C108.417 128.567 108.442 128.528 108.461 128.481C109.431 126.035 110.127 122.779 112.692 121.533C113.041 121.363 113.324 121.306 113.354 121.021C113.516 120.993 113.562 120.767 113.739 120.772C114.094 120.782 114.357 120.647 114.531 120.443C114.811 120.279 115.01 119.985 115 119.557L114.847 113.331L114.847 113.331Z" fill="#EBEFFF"/>
      <path d="M71.9724 113.461C72.0121 113.136 71.8195 112.779 71.3905 112.779C71.3363 112.779 71.2867 112.786 71.2401 112.797C71.2262 112.546 71.0405 112.353 70.7951 112.252C69.6215 111.768 68.5345 111.007 67.6592 110.063C66.7077 108.726 66.1631 107.108 66.1971 105.471C66.2051 105.082 65.8994 104.89 65.5921 104.893C65.5172 104.86 65.4353 104.842 65.3495 104.846C63.8849 104.928 62.4099 104.677 61.075 104.071C60.7764 103.935 60.4184 103.988 60.2411 104.286C60.0872 104.544 59.8548 104.969 60.155 105.105C60.2041 105.127 60.2545 105.146 60.3039 105.167C60.2499 105.258 60.2148 105.363 60.203 105.475C59.9904 107.493 60.2253 109.451 61.0547 111.351C61.6955 112.819 62.7588 114.079 64.0879 114.991C63.9267 115.099 63.8229 115.283 63.8131 115.488C63.7524 116.758 63.7767 118.029 63.8767 119.296C63.1719 119.545 62.5191 119.923 61.9537 120.412C61.6459 120.679 61.3694 120.979 61.1102 121.297C60.3701 121.838 59.7494 122.539 59.3146 123.352C58.6701 124.556 58.4029 125.894 58.2883 127.243C58.1935 127.873 58.1068 128.503 58.0107 129.131C57.9655 129.426 58.0601 129.718 58.3636 129.838C58.5734 129.92 58.8458 129.886 59.0164 129.731C59.0281 129.733 59.0392 129.736 59.051 129.738C61.0071 129.985 62.9803 130.058 64.9498 129.954C65.2122 129.94 65.3849 129.795 65.4713 129.611C65.8308 129.731 66.3029 129.551 66.2933 129.069C66.2273 125.782 68.334 122.54 71.457 121.303C71.6705 121.218 71.8373 121.055 71.8873 120.85C71.9571 120.76 72 120.642 72 120.497V113.642C72 113.575 71.9891 113.516 71.9724 113.461Z" fill="#EBEFFF"/>
      <path d="M115.153 117H115" stroke="#345BFF" strokeWidth="0.605812" strokeMiterlimit="10"/>
      <path d="M114.995 118.487H114.842" stroke="#345BFF" strokeWidth="0.605812" strokeMiterlimit="10"/>
      <path d="M53.9268 65.103V73.7957H80.4857V34.4126H33.2119C31.1055 34.4126 29.5187 36.3288 29.9115 38.3983L32.5256 52.1707C34.1666 60.2245 37.5372 65.0587 48.5481 65.103H63.4822" stroke="#495057" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M125.131 86.9683V49.1851C119.446 49.1851 114.842 53.7896 114.842 59.4738V73.8878" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M77.9334 19.458H80.486V34.4123H70.1968V27.1946C70.1968 22.9246 73.6634 19.458 77.9334 19.458V19.458Z" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M66.21 98.8091V103.997C66.21 107.904 68.6069 111.284 71.9989 112.738V120.86C68.6069 122.314 66.21 125.694 66.21 129.602V129.988" stroke="#495057" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M71.999 129.988H81.9776V120.098V119.635V116.96V115.514V104.484H102.962H106.817H107.293" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M53.9268 73.7949H113.4C113.887 73.7949 114.37 73.8272 114.842 73.8876C120.623 74.6006 125.131 79.5597 125.131 85.5219V92.2938L124.973 112.823V129.988H114.995" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M66.2098 104.484H65.6539C63.9176 104.484 62.2659 104.101 60.7794 103.412C56.7468 101.559 53.9269 97.4703 53.9269 92.757V73.7954H53.9268" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M107.292 98.8091V104.484" stroke="#495057" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M114.995 129.988H99.0581V127.99C99.0581 123.361 102.414 119.478 106.817 118.652V115.337C104.48 113.6 102.962 110.825 102.962 107.707V104.484" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M107.292 104.484C107.792 108.541 110.882 111.828 114.842 112.634V120.634C111.136 121.931 108.461 125.468 108.461 129.602V129.988" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M64.2803 129.988H66.2099H71.9989" stroke="#345BFF" strokeMiterlimit="10"/>
      <path d="M81.5745 129.989H58.0078V127.99C58.0078 124.051 60.441 120.651 63.8773 119.216V115.059C61.7422 113.315 60.3766 110.664 60.3766 107.707V103.413" stroke="#495057" strokeMiterlimit="10"/>
      <path d="M58.0081 41.5899C58.786 41.5899 59.4166 40.9593 59.4166 40.1814C59.4166 39.4035 58.786 38.7729 58.0081 38.7729C57.2302 38.7729 56.5996 39.4035 56.5996 40.1814C56.5996 40.9593 57.2302 41.5899 58.0081 41.5899Z" fill="#495057"/>
      <path d="M35.8834 34.4126V34.4569C35.8834 38.0825 33.7805 41.2167 30.731 42.7113L29.9117 38.3984C29.9117 38.3984 29.4108 34.9891 32.8975 34.4126H35.8834Z" fill="#495057"/>
      <path d="M124.989 82.7506L174.904 32.835" stroke="#495057" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M89.5288 118.295L102.962 104.951" stroke="#495057" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M128.967 88.3741L158.124 59.2168" stroke="#495057" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M130.669 28.6756C138.188 28.6756 144.283 22.5804 144.283 15.0617C144.283 7.54291 138.188 1.44775 130.669 1.44775C123.15 1.44775 117.055 7.54291 117.055 15.0617C117.055 22.5804 123.15 28.6756 130.669 28.6756Z" stroke="#495057" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M35.8836 114.505C38.5998 114.505 40.8018 112.303 40.8018 109.587C40.8018 106.871 38.5998 104.669 35.8836 104.669C33.1673 104.669 30.9653 106.871 30.9653 109.587C30.9653 112.303 33.1673 114.505 35.8836 114.505Z" stroke="#495057" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M125 130H175V67L139.932 102.177L125 116.593V130Z" fill="white" stroke="#495057" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M30.9951 122.505C29.2432 122.259 27.1649 122.992 25.5536 124.603C23.9424 126.214 23.2092 128.293 23.4553 130.044C25.2071 130.29 27.2855 129.557 28.8967 127.946C30.508 126.335 31.2412 124.256 30.9951 122.505Z" fill="white" stroke="#495057" strokeLinejoin="round"/>
      <path d="M21.1238 124.603C19.5126 122.992 17.4342 122.259 15.6823 122.505C15.4362 124.256 16.1694 126.335 17.7807 127.946C19.3919 129.557 21.4703 130.29 23.2222 130.044C23.4683 128.293 22.7351 126.214 21.1238 124.603Z" fill="white" stroke="#495057" strokeLinejoin="round"/>
      <path d="M23.4089 119.391C21.9961 120.455 21.0449 122.443 21.0449 124.722C21.0449 127.001 21.9961 128.989 23.4089 130.054C24.8216 128.989 25.7728 127.001 25.7728 124.722C25.7728 122.443 24.8216 120.455 23.4089 119.391Z" fill="white" stroke="#495057" strokeLinejoin="round"/>
      <path d="M82 130.091H0.936523" stroke="#495057" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M117.055 33.4782C117.486 34.6445 118.402 35.5604 119.569 35.9917C118.402 36.4229 117.487 37.3387 117.055 38.5052C116.624 37.3388 115.708 36.4229 114.542 35.9917C115.708 35.5603 116.624 34.6445 117.055 33.4782M117.055 31.9512C117.006 31.9512 116.961 31.982 116.944 32.0285L116.488 33.2651C116.119 34.2663 115.33 35.0554 114.329 35.4246L113.092 35.8806C113.046 35.8977 113.015 35.9421 113.015 35.9916C113.015 36.0412 113.046 36.0855 113.092 36.1027L114.329 36.5588C115.33 36.928 116.119 37.7171 116.488 38.7182L116.944 39.9549C116.961 40.0014 117.006 40.0323 117.055 40.0323C117.105 40.0323 117.149 40.0014 117.166 39.9549L117.622 38.7182C117.991 37.7171 118.781 36.9279 119.782 36.5588L121.018 36.1027C121.065 36.0855 121.096 36.0412 121.096 35.9916C121.096 35.9421 121.065 35.8977 121.018 35.8806L119.782 35.4246C118.781 35.0554 117.991 34.2663 117.622 33.2652L117.166 32.0286C117.149 31.9821 117.105 31.9512 117.055 31.9512L117.055 31.9512Z" fill="#495057"/>
      <path d="M112.493 24.4189L111.847 24.1808C111.324 23.988 110.912 23.576 110.719 23.0533L110.481 22.4076C110.472 22.3833 110.449 22.3672 110.423 22.3672C110.397 22.3672 110.374 22.3833 110.365 22.4076L110.127 23.0533C109.934 23.576 109.522 23.988 109 24.1808L108.354 24.419C108.33 24.4279 108.313 24.4511 108.313 24.4769C108.313 24.5028 108.33 24.5259 108.354 24.5349L109 24.7731C109.522 24.9658 109.934 25.3779 110.127 25.9006L110.365 26.5464C110.374 26.5706 110.397 26.5868 110.423 26.5868C110.449 26.5868 110.472 26.5706 110.481 26.5464L110.719 25.9006C110.912 25.3779 111.324 24.9658 111.847 24.7731L112.493 24.5349C112.517 24.5259 112.533 24.5028 112.533 24.4769C112.533 24.4511 112.517 24.4279 112.493 24.419V24.4189Z" fill="#495057"/>
      </svg>
    </Box>
  );
}

export default memo(WelcomeDogIllustration);
